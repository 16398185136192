export const STATUS = {
  default: {
    value: 1,
    label: 'Không hợp lệ',
    color: 'secondary'
  },
  waitingCancel: {
    value: 2,
    label: 'Chưa gửi',
    color: 'warning'
  },
  success: {
    value: 3,
    label: 'Thành công',
    color: 'success'
  },
  failed: {
    value: 4,
    label: 'Thất bại',
    color: 'danger'
  },
  cancel: {
    value: 5,
    label: 'Đã hủy',
    color: 'secondary'
  }
}

export const PROVIDER = {
  hilo: {
    value: 'Hilo',
    label: 'Hilo'
  },
  hilo_htx26: {
    value: 'Hilo_htx26',
    label: 'Hilo HTX 26'
  },
  bkav: {
    value: 'bkav',
    label: 'Bkav'
  }
}

export const MESSAGE_WARNING_SELECTED = {
  resend: {
    whenSuccess: 'Vé này đã gửi thành công, sẽ không gửi lại',
    whenCanceled: 'Vé này đã hủy, sẽ không gửi lại',
    whenAll: 'Những vé ở trạng thái thành công, đã hủy sẽ không gửi lại'
  }
}

export const TASKS = {
  resend: { value: 'resend', label: 'Gửi lại' },
  refresh: { value: 'refresh', label: 'Cập nhật trạng thái' }
  // cancel: { value: 'cancel', label: 'Hủy vé' }
}
